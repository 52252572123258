(function ($) {
    var menuTimer, $mobilemenuWrapper, $multiselectMobile, $mobileOverlay, $mobilemenu, $navbarToggler, $navbarTogglerHtml
    var touchMoveScrollSwitch = true;
    var focusOnOpen = false;
    var isDragged = false;
    $(function () {
        jQuery.extend(verge);
        //Set Cached selectors
        $mobilemenuWrapper = $("#menu");
        $multiselectMobile = $("#menu", "#menu_multilevelpushmenu");
        $mobileOverlay = $(".mobile-overlay");
        var $mobileTopSection = $(".mobile-header-wrapper");
        $navbarToggler = $("#mobileNavbarToggler");
        //$navbarSearchToggler = $("#mobileNavbarSearchToggler");
        $mobileSearchInput = $("#mobileSearchInput");

        //Instantiate mobile menu object into cover mode with HTML implementation
        $mobilemenuWrapper.multilevelpushmenu({
            mode: 'cover',
            fullCollapse: true,
            backItemIcon: 'fa fa-caret-left',
            groupIcon: 'fa fa-caret-right',
            menuWidth: '90%',
            menuHeight: '100%',
            collapsed: true,
            onMenuReady: function () {
                $mobilemenu = $("#menu_multilevelpushmenu");
                $mobilemenuWrapper.removeClass('domReady');
            },
            onCollapseMenuStart: function () {
                if ($mobilemenuWrapper.multilevelpushmenu('activemenu').attr('data-level') === "0") {
                    $mobileOverlay.addClass('out');
                }
            },
            onCollapseMenuEnd: function () {
                if (!$mobilemenuWrapper.multilevelpushmenu('activemenu').length) {
                    $('html').removeClass('freeze');
                    $('body').removeClass('freeze');
                    setTimeout(function () {
                        $mobileOverlay.removeClass('show').removeClass('out');
                    }, 100);
                }
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
            },
            onExpandMenuStart: function () {
                setMobileMenuHeight();
                $mobileOverlay.addClass('show');
                $('html').addClass('freeze');
                $('body').addClass('freeze');
                //Fix a bug where the menu doesn't open up to be full-width
                $mobilemenuWrapper.multilevelpushmenu('redraw');
            },
            onExpandMenuEnd: function () {
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                if (focusOnOpen !== false) {
                    $(focusOnOpen).focus();
                    focusOnOpen = false;
                }
            },
            onMenuSwipe: function () {
                //Prevent menu swipe event from stealing focus from form elements
                return false;
            },
            onItemClick: function () {

                if (!isDragged) {
                    //Manually send the user to a link provided it has a valid href
                    var event = arguments[0];
                    //console.log('changed touches ' + $(arguments[0].originalEvent.changedTouches));
                    if (touchMoveScrollSwitch) {
                        var $anchor = $(event.target);
                        var url = $anchor.attr('href');
                        var userTarget = $anchor.attr('target');
                        var smartTarget = url.startsWith("/") || url.startsWith("tel:") || url.startsWith("mailto:") ? "_self" : "_blank";
                        var target = userTarget || smartTarget;

                        if (url.length) {
                            if (target !== "" && target == "_blank") {
                                window.open(url, target);
                            }
                            else {
                                window.location.href = url;
                            }
                        }
                    }
                }
            },
            onGroupItemClick: function () {

                //AJS 10/16/17, if level 0, swapout search spot for title
                //$menuLevelHolder = arguments[1];
                //$item = arguments[2];


                //alert(isDragged);
                if (isDragged) {
                    //force stop overflow flash
                    $mobilemenuWrapper.find("> nav.multilevelpushmenu_wrapper > .levelHolderClass").addClass("no-overflow-flash");
                    //force stay on menu, they are scrolling not clicking
                    $mobilemenuWrapper.multilevelpushmenu('collapse', arguments[2].level);
                }
                else {
                    /*if (typeof $menuLevelHolder === 'undefined')
                    { } else {
                        if ($menuLevelHolder.attr("data-level") == "0") {
                            //swap out search space
                            $mobileTopTitleSection.html($item.find(".title").text());
                            $mobileTopSection.addClass("title-view");
                        }
                    }
                    $mobilemenuWrapper.find(".levelHolderClass > ul").scrollTop(0);*/

                }


            },
        });

        var touchTimer;
        $('.levelHolderClass').on('scroll', function (e) {
            touchMoveScrollSwitch = false;
            clearTimeout(touchTimer);
            touchTimer = setTimeout(function () {
                touchMoveScrollSwitch = true;
            }, 200);
        });

        $mobilemenuWrapper.find('.levelHolderClass > ul > li').on('touchstart', function (e) {
            //start
            //$mobilemenuWrapper.find('li.primary-nav-link').removeClass("noclick");
            isDragged = false;
            //alert("start");
        });
        
        $mobilemenuWrapper.find('.levelHolderClass > ul').on('scroll touchmove', function (e) {
            //move
            //try stop click 
            //$(this).addClass("noclick");
            isDragged = true;
        });

        //Redraw the mobile menu to enable responsiveness
        $(window).on('resize', function () {
            setMobileMenuHeight();
            //Only run resize function every 50ms while in the correct viewport to lower overhead of client function
            if (isViewportInMobile()) {
                clearTimeout(menuTimer);
                menuTimer = setTimeout(function () {
                    $mobilemenuWrapper.multilevelpushmenu('redraw');
                    ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                }, 50);
                collapseMenu();
            }
        });

        $navbarToggler.on('click', function () {
            // Toggle display of mobile menu and transform hamburger into close button
            toggleMenuClick();
        });

        function toggleMenuClick() {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length === 0) {
                expandMenu();
            }
            else {
                collapseMenu();
            }
        }
        function setMobileMenuHeight() {
            //var backheight = $mobilemenuWrapper.find(".backItemClass").height();
            //$mobilemenuWrapper.find(".levelHolderClass > ul").height(($(window).height() - $mobileTopSection.outerHeight() - backheight - 2) + "px")
        }
        function expandMenu() {
            $navbarTogglerHtml = $navbarToggler.html();
            $mobilemenuWrapper.multilevelpushmenu('expand');
            $navbarToggler.html("X").addClass("closeLabel");
        }

        function collapseMenu() {
            $mobilemenuWrapper.multilevelpushmenu('collapse');
            $navbarToggler.removeClass("closeLabel").html($navbarTogglerHtml);
        }

        function ScrollMobileMenu($element) {
            if (!$.inViewport($element.find('>ul>li:last-child'))) {
                $element.addClass('scrolled');
            }
            else {
                $element.removeClass('scrolled');
            }
        }

        $($mobileOverlay).click(function (e) {
            collapseMenu();
        });

        /* Workaround what I believe to be a stacking context issue - header logo is clickable behind mobile menu search form */
        $(".nav-wrapper").click(function (e) {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length !== 0) return false;
        });
    });

    function isViewportInMobile(mobileWidthOverride) {
        var widthToCheckAgainst = mobileWidthOverride || 768;
        return window.innerWidth < widthToCheckAgainst;
    }
})(jQuery);