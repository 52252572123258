var $maps, $infotext;

function initGMap() {

    var infowindow;
    $maps = $('.maps .map_canvas');
    $maps.each(function (index, Element) {
        $infotext = $(Element).children('.infotext');

        var myOptions = {
            'zoom': parseInt($infotext.children('.locations').first().children('.zoom').text()),
            'mapTypeId': google.maps.MapTypeId.ROADMAP
        };

        var map;
        var geocoder;
        var marker;
        var addresses = [];
        var address = null;
        var contents = [];

        var places = new Array();
        var Markers = new Array();

        $(Element).children('.infotext').children('.locations').each(function (index, ele) {
            var addr = new Object();
            
            var lat = $(this).children('.latitude').text();
            var lng = $(this).children('.longitude').text();

            if (lat != "0" && lng != "0") {
                addr.coordinates = new google.maps.LatLng(lat, lng);
            }

            addr.address =
                $(this).children('.address').text() + ", " + $(this).children('.city').text() + ", " +
                $(this).children('.state').text() + ", " + $(this).children('.zip').text() + ", " +
                $(this).children('.country').text();
            
            addresses.push(addr);

            var content = new Object();
            content.content = SetInfowindowContent($(this), addr.coordinates);
            content.item = $(this);
            contents.push(content);
        });

        geocoder = new google.maps.Geocoder();
        myOptions.center = new google.maps.LatLng(0, 0);
        infowindow = new google.maps.InfoWindow();
        map = new google.maps.Map($(Element).children('.mapWrap')[0], myOptions);
        var geocoderType = "standard";

        for (var i = 0; i < addresses.length; i++) {

            var getCallback = function (index, type) {
                return function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var latlng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                        Markers[index] = new google.maps.Marker({
                            map: map,
                            position: latlng,
                            title: $infotext.children('.locations').children('.location').text()
                        });

                        //Reverse lookup was used so lets update the stored details with the retrieved address from the coordinates so the infowindow will match the pin
                        if (type == "reverse") {
                            for (var j = 0; j < results.length; j++) {
                                if (results[j].types.indexOf('street_address') > -1) {
                                    extractAddress(results[j].address_components, contents[index]);
                                    contents[index].content = SetInfowindowContent(contents[index].item, latlng);
                                    break;
                                }
                            }
                        }

                        Markers[index]._content = contents[index].content;
                        Markers[index].addListener('click', function (e) {
                            infowindow.setContent(this._content);
                            infowindow.open(map, this);
                        });

                        window.onresize = function () {
                            infowindow.close();
                        };

                        places.push(Markers[index].position);

                        if (places.length === addresses.length) {
                            var setBounds = addresses.length > 1;

                            geocodeAddress(places, setBounds, map);
                        }

                    } else {
                        console.error('The address could not be found for the following reason: ' + status);
                    }
                };
            };

            if (addresses[i].coordinates != null) {
                geocoderType = "reverse";
                geocoder.geocode({ 'latLng': addresses[i].coordinates }, getCallback(i, geocoderType));
            }
            else {
                geocoder.geocode({ 'address': addresses[i].address }, getCallback(i, geocoderType));
            }
        }

    });
}

function extractAddress(components, contents)
{
    var streetAddress;
    var $location = contents.item.children();

    for (var i = 0; i < components.length; i++) {
        for (var j = 0; j < components[i].types.length; j++) {
            switch (components[i].types[j]) {
                case "street_number": //Street Number
                    streetAddress = components[i].long_name + " ";
                    break;
                case "route": //Street Name
                    streetAddress += components[i].long_name;
                    break;
                //case "neighborhood": //Neighborhood
                    
                //    break;
                case "locality": //City
                    $location.filter('.city').text(components[i].long_name);
                    break;
                case "administrative_area_level_1": //State
                    $location.filter('.state').text(components[i].long_name);
                    break;
                case "country": //Country
                    $location.filter('.country').text(components[i].short_name);
                    break;
                case "postal_code": //Zip
                    $location.filter('.zip').text(components[i].long_name);
                    break;
            }
            //console.log(components[i].types[j] + ": " + components[i].long_name);
        }
    }
    if (streetAddress.length) {
        $location.filter('.address').text(streetAddress);
    }
}

function geocodeAddress(places, setBounds, map) {

    var bounds = new google.maps.LatLngBounds();

    for (var j = 0; j < places.length; j++) {
        bounds.extend(places[j]);
    }

    map.setCenter(bounds.getCenter());

    //Only overwrite the zoom level if there are multiple markers that need to fit into the map window
    if (setBounds) map.fitBounds(bounds);

    google.maps.event.addDomListener(window, "resize", function () {
        google.maps.event.trigger(map, "resize");

        map.setCenter(bounds.getCenter());
        if (setBounds) map.fitBounds(bounds);
    });
}

function SetInfowindowContent(item, coordinates) {
    var item = item.children();

    var name = item.filter('.location').text();
    var address = item.filter('.address').text();
    var city = item.filter('.city').text();
    var state = item.filter('.state').text();
    var zip = item.filter('.zip').text();
    var country = item.filter('.country').text();
    var phone = item.filter('.phone').text();
    var email = item.filter('.email').text();
    var fax = item.filter('.fax').text();

    var infoString = "<div class='info'><h5>{0}</h5>".format(name);

    if (HasContent(address)) {
        infoString += "<p>{0}".format(address);
    }

    infoString += "<br />"

    if (HasContent(city) && HasContent(state) && HasContent(zip)) {
        infoString += "{0}, {1} {2}".format(city, state, zip);
    }
    else if ((HasContent(city) && !HasContent(state)) || (!HasContent(city) && HasContent(state)) && HasContent(zip)) {
        infoString += "{0} {1}".format(HasContent(city) ? city : state, zip);
    }
    else {
        infoString += "{0}{1} {2}".format(HasContent(city) ? city + ", " : "", state, zip);
    }

    infoString += "</p>"

    infoString += "<p>";
    if (HasContent(phone)) infoString += "<span style='display: block;'><i class='fa fa-phone'>&nbsp;</i><a href='tel:+1{0}'>{1}</a></span>".format(phone.replace(/[^\d]/g, ''), phone);
    if (HasContent(email)) infoString += "<span style='display: block;'><i class='fa fa-envelope-o'>&nbsp;</i><a href='mailto:{0}'>{0}</a></span>".format(email);
    
    if (coordinates != null) infoString += "<span style='display: block;'><i class='fa fa-location-arrow'>&nbsp;</i><a href='https://www.google.com/maps/dir/Current+Location/{0}'>Get Directions</a></span>".format("{0},{1}".format(coordinates.lat(), coordinates.lng()));
    else infoString += "<span style='display: block;'><i class='fa fa-location-arrow'>&nbsp;</i><a href='https://www.google.com/maps/dir/Current+Location/{0}'>Get Directions</a></span>".format(encodeURIComponent("{0}+{1}+{2}+{3}".format(address, city, state, zip)));

    infoString += "</p>";


    infoString += "</div>";

    return infoString;
}

function HasContent(prop) {
    return prop && prop.length;
}

//Provide support for .format() [equivalent of C# string.Format method]
String.prototype.format = function () {
    var s = this,
        i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
    }
    return s;
};
