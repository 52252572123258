jQuery.extend(verge);
var timer;
var scrolling = false;
var scrolledAmnt = 0;
$(document).ready(function () {
    
    var mobileNavId = "#mobile-primary-navigation";
    if ($(mobileNavId).length > 0) {
        $(mobileNavId).multilevelpushmenu({
            mode: 'cover',
            containersToPush: [$('header'), $('.footer-main'), $('main')],
            menuWidth: '100%',
            menuHeight: '100%'
        });
        $(window).resize(function () {
            $(mobileNavId).multilevelpushmenu('redraw');
        });
    }
    tagIE();

    //Initialize the clickable links
    Marathon.SetLinksAsClickable.OnReady();
    
    //Kickoff the mega-menu adjustment
    adjustMenu();
    handleAccordionContent();

    //matchheights
    var lightGrayCards = $('.card-section .light-gray-short-card .card-block');
    if (lightGrayCards.exists()) {
        lightGrayCards.matchHeight();
    }
    var textCTACards = $('.card-section .text-card-cta .card-text');
    if (textCTACards.exists()) {
        //manually set rows
        //var $t_cta_rows = textCTACards.closes
        //$.fn.matchHeight._apply([item-1, item-3, item-4, item-2], {byRow:true})

        $('section.card-section').each(function (i, elem) {
            $(elem)
                .find('.text-card-cta .card-block')   // Only children of this row
                .matchHeight({ byRow: false }); // Row detection gets confused so disable it
        });
    }
    var boardMembers = $('.board-of-directors-grid .text-content');
    if (boardMembers.exists()) {
        boardMembers.matchHeight();
    }

    $(window).on('resize', function () {
        clearTimeout(timer);
        timer = setTimeout(function () {
            adjustMenu();
            handleExpandedAccordions();
            //adjustBanner();
        }, 50);

    });
    //skip content click
    $(".skip-content").click(function (e) {
        e.preventDefault();
        $("html").animate({ scrollTop: ($("#skip-destination").offset().top - $("header").height() + 42) }, '200');
    });
    /*$(window).on('scroll', function (e) {
        // called when the window is scrolled.
        scrolling = true;
        scrolledAmnt = document.body.scrollTop;

        setTimeout(function () {
            console.log("scrolling = " + scrolling);
            if (scrolledAmnt == document.body.scrollTop) {
                scrolling = false;
            }
        }, 400);
    });*/
});

function adjustMenu() {
    var i = 1;

    $(".megamenu:visible").each(function (e) {


        var elm = $(this);
        var mm = $(elm).children('.dropdown-menu').first();

        //mm.removeAttr('style');
        $(mm).removeClass('center');
        $(elm).css('position', 'relative');

        var off = $(mm).offset();
        var l = off.left; // position of right of parent item
        var ddCenter = (Math.ceil(($(elm).width() / 2)) + l); // Center point of the parent menu item from the left
        var w = $(mm).outerWidth(); // mEGA MENU WIDTH

        var docH = $(document).height();
        var docW = $(document).width();

        //console.log('right edge -' + rightEdge + ' right offset - ' + rightEdgeOffset);
        //console.log(elm.text().trim().substr(0, 15));
        //console.log("left offset - " + l + " docW - " + docW);

        var offsetVal = docW - (l + w);

        //console.log(i + " nav width: " + ($('nav').width() * .5));
        //console.log(i + " item width: " + w);
        //console.log(offsetVal);

        if (l < 0) {
            //alert("yep" + elm.text());
            $(mm).addClass('center');
            $(elm).css('position', 'static');
            //var rightText = "right: " + offsetVal + "px!important";
            //$(mm).css('right', (15 - offsetVal) + "px");

        }
        else {
            //$(mm).css('right', 0);
            $(mm).removeClass('center');
            $(elm).css('position', 'relative');
        }
        i++;
    });
}
function adjustBanner() {

    if (!scrolling) {
        if ($(window).width() < 768) {
            $(".banner.featured").height($(window).height() - $("body > header").height());
            $(".banner.subpage").height(($(window).height() * 0.6) - $("body > header").height());
        }
        else {
            $(".banner.featured").css("height", "");
            $(".banner.subpage").css("height", "");
        }
    }
}
function handleAccordionContent() {
    $(".accordion-content a.expand-collapse").click(function (e) {
        e.preventDefault();
        var $parent_item = $(this).closest(".accordion-item");


        if ($parent_item.hasClass("collapsed")){
            $parent_item.addClass("expanded").removeClass("collapsed");

            var ad_height = $parent_item.find(".additional-content > div").outerHeight();// + 16;
            $parent_item.find(".additional-content").css("height", ad_height + "px");
            //setTimeout(function () { $parent_item.find(".additional-content").css("height", "auto"); }, 400);

        } else
        if ($parent_item.hasClass("expanded")) {
            $parent_item.addClass("collapsed").removeClass("expanded");
            $parent_item.find(".additional-content").css("height", "");
        }

    });
    
}
function handleExpandedAccordions() {
    $(".accordion-content .accordion-item.expanded").each(function () {
        $self = $(this);
        $self.find(".additional-content").css("height", $self.find(".additional-content > div").outerHeight());
    });
}
function tagIE()
{
    var myNav = navigator.userAgent.toLowerCase();
    var is10orbelow = (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
    if (is10orbelow)  // If Internet Explorer, return version number
    {
        //add ie classd to html
        var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
        root.className += ' ie10below';
        //$("html").addClass("ie10below");
    }
    else {
        var isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
        if (isIE11) {
            var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
            root.className += ' ie11';
        }
    }
}